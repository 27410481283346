import routes from '../../routes';

const breadcrumbsServiceList = [
  { title: routes.customers.title, link: routes.customers.path() },
  {
    title: routes.services.title,
    link: routes.services.path(),
    active: true,
    last: true,
  },
];
export default breadcrumbsServiceList;
