import routes from '../../routes';

const breadcrumbsServiceInfo = [
  { title: routes.customers.title, link: routes.customers.path() },
  {
    title: routes.services.title,
    link: routes.services.path(),
  },
];
export default breadcrumbsServiceInfo;
