import { ISelectValue } from '../../interfaces/select-value';

type DefaultValueForSelectType = (
  data: ISelectValue[],
  id: string,
) => ISelectValue;

const defaultValueForSelect: DefaultValueForSelectType = (data = [], id) =>
  data.find((s) => s.value === id);
export default defaultValueForSelect;
