import { IRoute } from '../interfaces/routes';

interface IRoutes {
  dashboard: IRoute;
  employeeDashboard?: IRoute;
  customers: IRoute;
  addCustomer: IRoute;
  createService: IRoute;
  schedule?: IRoute;
  services: IRoute;
  installations?: IRoute;
  tasks: IRoute;
  teams?: IRoute;
  employees: IRoute;
  plans?: IRoute;
  offers?: IRoute;
  invoices?: IRoute;
  workflows?: IRoute;
  report: IRoute;
  tenant: IRoute;

  [rest: string]: IRoute;
}

const endOfLine = (param = '') => param && `/${param}`;

export const routes: IRoutes = {
  // sidebar.menu
  dashboard: {
    title: 'sidebar.dashboard',
    path: (param = '') => `/dashboard${endOfLine(param)}`,
    icon: 'bx bxs-dashboard',
    sidebarTitle: 'sidebar.menu',
  },
  // employeeDashboard: {
  //   title: 'sidebar.employeeDashboard',
  //   path: (param = '') => `/${endOfLine(param)}`,
  //   icon: 'fas fa-square',
  //   sidebarTitle: 'sidebar.menu',
  // },
  customers: {
    title: 'sidebar.customers',
    path: (param = '') => `/customers${endOfLine(param)}`,
    icon: 'bx bxs-contact',
    sidebarTitle: 'sidebar.menu',
  },
  workflows: {
    title: 'sidebar.workflows',
    path: (param = '') => `/workflows${endOfLine(param)}`,
    icon: 'bx bx-shape-circle',
    sidebarTitle: 'sidebar.menu',
  },
  addCustomer: {
    title: 'customer.newCustomer.addCustomer',
    path: (param = '') => `/customers/add-customer${endOfLine(param)}`,
    icon: 'bx bxs-contact',
    sidebarTitle: 'sidebar.menu',
    subChildLink: true,
  },
  createService: {
    title: 'servicesPage.createService',
    path: (param = '') => `/services/create-service${endOfLine(param)}`,
    sidebarTitle: 'sidebar.menu',
    subChildLink: true,
  },
  // schedule: {
  //   title: 'sidebar.schedule',
  //   path: (param = '') => `/${endOfLine(param)}`,
  //   icon: 'bx bx-calendar',
  //   sidebarTitle: 'sidebar.menu',
  // },
  services: {
    title: 'sidebar.services',
    path: (param = '') => `/services${endOfLine(param)}`,
    icon: 'fas fa-clone',
    sidebarTitle: 'sidebar.menu',
  },
  addNote: {
    title: 'servicesPage.addNote',
    path: (param = '') => `/services/add-note${endOfLine(param)}`,
    subChildLink: true,
  },
  // installations: {
  //   title: 'sidebar.installations',
  //   path: (param = '') => `/${endOfLine(param)}`,
  //   icon: 'bx bxs-map',
  //   sidebarTitle: 'sidebar.menu',
  // },
  tasks: {
    title: 'sidebar.tasks',
    path: (param = '') => `/tasks${endOfLine(param)}`,
    icon: 'bx bx-task',
    sidebarTitle: 'sidebar.menu',
  },
  createTask: {
    title: 'tasks.createTask',
    path: (param = '') => `/tasks/create-task${endOfLine(param)}`,
    subChildLink: true,
  },
  // teams: {
  //   title: 'sidebar.teams',
  //   path: (param = '') => `/${endOfLine(param)}`,
  //   icon: 'bx bx-group',
  //   sidebarTitle: 'sidebar.menu',
  // },
  employees: {
    title: 'sidebar.employees',
    path: (param = '') => `/employees${endOfLine(param)}`,
    icon: 'bx bx-id-card',
    sidebarTitle: 'sidebar.menu',
  },

  report: {
    title: 'sidebar.reports',
    path: (param = '') => `/reports${endOfLine(param)}`,
    icon: 'bx bxs-bar-chart-alt-2',
    sidebarTitle: 'sidebar.menu',
  },

  // routes: {
  //   title: 'sidebar.routes',
  //   path: (param = '') => `/${endOfLine(param)}`,
  //   icon: 'bx bx-map-alt',
  //   sidebarTitle: 'sidebar.menu',
  // },
  plans: {
    title: 'plans',
    path: (param = '') => `/tenant/plans${endOfLine(param)}`,
    subChildLink: true,
  },
  tenant: {
    title: 'tenant',
    path: (param = '') => `/tenant${endOfLine(param)}`,
    subChildLink: true,
  },

  offers: {
    title: 'sidebar.offers',
    path: (param = '') => `/${endOfLine(param)}`,
    icon: 'bx bx-file',
    sidebarTitle: 'sidebar.documents',
  },
  invoices: {
    title: 'sidebar.invoices',
    path: (param = '') => `/${endOfLine(param)}`,
    icon: 'bx bx-file',
    sidebarTitle: 'sidebar.documents',
  },
  contracts: {
    title: 'sidebar.contracts',
    path: (param = '') => `/${endOfLine(param)}`,
    icon: 'bx bx-file',
    sidebarTitle: 'sidebar.documents',
  },
  projects: {
    title: 'sidebar.projects',
    path: (param = '') => `/${endOfLine(param)}`,
    icon: 'bx bx-file',
    sidebarTitle: 'sidebar.documents',
  },
  // sidebar.others
  reports: {
    title: 'sidebar.reports',
    path: (param = '') => `/${endOfLine(param)}`,
    icon: 'bx bx-receipt',
    sidebarTitle: 'sidebar.others',
  },
  notifications: {
    title: 'sidebar.notifications',
    path: (param = '') => `/${endOfLine(param)}`,
    icon: 'far fa-comment-alt',
    sidebarTitle: 'sidebar.others',
  },
  // sidebar.settings
  sidebarSettings: {
    title: 'sidebar.settings',
    icon: 'bx bx-cog',
    sidebarTitle: 'sidebar.others',
    subMenuParent: true,
  },
  settingsMenuCallScenarios: {
    title: 'sidebar.settingsMenu.callScenarios',
    path: (param = '') => `/${endOfLine(param)}`,
    sidebarTitle: 'sidebar.others',
    subMenuTitle: 'sidebar.settings',
  },
  settingsMenuPermissions: {
    title: 'sidebar.settingsMenu.permissions',
    path: (param = '') => `/${endOfLine(param)}`,
    sidebarTitle: 'sidebar.others',
    subMenuTitle: 'sidebar.settings',
  },
  settingsMenuBilling: {
    title: 'sidebar.settingsMenu.billing',
    path: (param = '') => `/${endOfLine(param)}`,
    sidebarTitle: 'sidebar.others',
    subMenuTitle: 'sidebar.settings',
  },
};

export default routes;
