import {
  EditTenantInformationProps,
  EmployeeListProps,
} from '@digital.jit/services/tenant/ui-models';
import {
  CustomersListProps,
  IEmployeeTimelogDetailsResponse,
  IEmployeeTimelogResponse,
  INoteProps,
  ITaskProps,
  ServiceDetailsProps,
  ServiceNoteListProps,
  ServicesListProps,
  ServiceTaskListProps,
} from '@digital.jit/services/customer/ui-models';
import {
  IWorkflow,
  IWorkflowTask,
  IWorkflowTaskStep,
} from '@digital.jit/services/tenant/domain';
import api from '../../api';
import { fetcher } from './fetcher';
import { IDateData, IStepProps } from '../interfaces';
import { IJitSession } from '../../pages/api/auth/jitSession.interface';

// eslint-disable-next-line import/prefer-default-export
export const fetcherList = {
  tenantInfo: () =>
    fetcher<EditTenantInformationProps>(() =>
      api.tenant.getTenantInformation(),
    ),
  employeeList: () =>
    fetcher<EmployeeListProps[]>(() => api.tenant.getTenantEmployeesList()),

  employeeId: (id: string) =>
    fetcher<EmployeeListProps>(() => api.tenant.getEmployeeById(id)),

  customerList: () =>
    fetcher<CustomersListProps[]>(() => api.customer.getCustomersList()),

  customerId: (id: string) =>
    fetcher<CustomersListProps>(() => api.customer.getCustomerById(id)),

  getTaskById: (id: string) =>
    fetcher<ITaskProps>(() => api.customer.getTaskById(id)),

  taskList: () => fetcher<ITaskProps[]>(() => api.customer.getTasksList()),

  taskNoteList: (taskId: string) =>
    fetcher<INoteProps[]>(() => api.customer.getTaskNoteList(taskId)),

  taskSteps: (taskId: string) =>
    fetcher<IStepProps[]>(() => api.customer.getTaskSteps(taskId)),

  servicesList: () =>
    fetcher<ServicesListProps[]>(() => api.customer.getServicesList()),

  serviceTaskList: (serviceId: string) =>
    fetcher<ServiceTaskListProps[]>(() =>
      api.customer.getServiceTasks(serviceId),
    ),

  serviceNoteList: (serviceId: string) =>
    fetcher<ServiceNoteListProps[]>(() =>
      api.customer.getServiceNoteList(serviceId),
    ),

  tasksList: () => fetcher<ITaskProps[]>(() => api.customer.getTasksList()),

  serviceById: (id: string) =>
    fetcher<ServiceDetailsProps>(() => api.customer.getServiceById(id)),

  taskListForToday: () =>
    fetcher<ITaskProps[]>(() => api.customer.getTaskForToday()),

  serviceListForToday: () =>
    fetcher<ServicesListProps[]>(() => api.customer.getServiceForToday()),

  customerServices: (id: string) =>
    fetcher<ServicesListProps[]>(() => api.customer.getCustomerServices(id)),

  workflowList: () => fetcher<IWorkflow[]>(() => api.tenant.getWorkflowList()),

  suggestedWorkflowList: () =>
    fetcher<IWorkflow[]>(() => api.tenant.getSuggestedWorkflowList()),

  workflowTaskList: (workflowId: string) =>
    fetcher<IWorkflowTask[]>(() => api.tenant.getWorkflowTasksList(workflowId)),

  workflowTaskStepsList: (taskId: string) =>
    fetcher<IWorkflowTaskStep[]>(() =>
      api.tenant.getWorkflowTaskStepsList(taskId),
    ),

  workflowById: (workflowId: string) =>
    fetcher<IWorkflow>(() => api.tenant.getWorkflowById(workflowId)),

  employeeWorklogList: (id: string, dates: IDateData) =>
    fetcher<IEmployeeTimelogDetailsResponse>(() =>
      api.customer.getEmployeeWorklog(id, dates),
    ),

  employeesTimelogReport: (startDate: Date, endDate: Date) =>
    fetcher<IEmployeeTimelogResponse[]>(() =>
      api.customer.getEmployeesTimelogReport(startDate, endDate),
    ),

  employeeTasks: (id: string) =>
    fetcher<ITaskProps[]>(() => api.customer.getEmployeeTasksList(id)),

  getUserTenantsList: () => fetcher(() => api.tenant.getTenantsList()),
  getUserSession: () =>
    fetcher<IJitSession>(() => api.tenant.getUserSessionInformation()),
};
