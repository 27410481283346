import routes from '../../routes';

const breadcrumbsTasksLink = [
  { title: routes.customers.title, link: routes.customers.path() },
  {
    title: routes.services.title,
    link: routes.services.path(),
  },
  {
    title: routes.tasks.title,
    link: routes.tasks.path(),
    active: true,
    last: true,
  },
];
export default breadcrumbsTasksLink;
