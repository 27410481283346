import { IDynamicType } from '../interfaces/dynamic-type';
import ls from './local-storage';

const LS_LANGUAGE_KEY = 'language';

export const listSorting = <T>(column: string, direction: string, state: T[]) =>
  state.sort((a, b) => {
    if (!a[column]) {
      return 1;
    }
    if (!b[column]) {
      return -1;
    }
    if (a[column] && b[column]) {
      if (typeof a[column] === 'object' || typeof b[column] === 'object') {
        const firstObjectProperty = Object.keys(a[column])[0];
        const AColumn = (a[column] as IDynamicType)[firstObjectProperty];
        const BColumn = (b[column] as IDynamicType)[firstObjectProperty];

        return direction === 'asc'
          ? AColumn?.localeCompare(BColumn)
          : BColumn?.localeCompare(AColumn);
      }

      a[column] = a[column].toString();
      b[column] = b[column].toString();

      return direction === 'asc'
        ? (a[column] as string)?.localeCompare(
            b[column],
            ls.get(LS_LANGUAGE_KEY) || 'en',
            {
              numeric: true,
              ignorePunctuation: true,
            },
          )
        : (b[column] as string)?.localeCompare(
            a[column],
            ls.get(LS_LANGUAGE_KEY) || 'en',
            {
              numeric: true,
              ignorePunctuation: true,
            },
          );
    }

    return 0;
  });
export default listSorting;
