export const registerServiceWorker = () => {
  navigator.serviceWorker.register('/sw.js').then(
    (registration) => {
      console.log(
        'Service Worker registration successful with scope: ',
        registration.scope,
      );
    },
    (err) => {
      console.log('Service Worker registration failed: ', err);
    },
  );
};
