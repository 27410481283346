import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import getConfig from 'next/config';
import { IConfig } from '../../shared/interfaces/rest-api-config';
import { IError, JitMicroservice } from '../../shared/interfaces';
import { httpStatusCode } from '../../shared/constants/http-status-code';
import { IJitSession } from '../../pages/api/auth/jitSession.interface';
import { IResponseParamsProps } from '../../shared/interfaces/api/api-utils';
import responseToaster from '../../shared/helpers/response-toaster';
import {
  getSessionCookie,
  removeSessionCookies,
  setSessionCookies,
} from '../../shared/context/session';
import { FirebaseApiInstance } from '../firebase';
import { IFirebaseUser } from '../../shared/interfaces/api/firebase/user';
import { camelize } from '../../shared/helpers';
import { JitNextConfig } from '../../types/jit-next-config.interface';
import { getCurrentUserToken } from '../firebase/firebase-user';

export const requestInterceptor = async (
  config: IConfig,
  apiVersion: string,
): Promise<IConfig> => {
  let port = '3333';
  let newConfig: IConfig = config;

  if (process.env.NODE_ENV === 'development') {
    if (newConfig.headers.jitMicroservice === JitMicroservice.customer) {
      port = '3334';
    } else if (newConfig.headers.jitMicroservice === JitMicroservice.tenant) {
      port = '3335';
    }
    newConfig.baseURL = `http://ws.tenants.jit.pove4e.info:${port}/${apiVersion}`;
  }
  if (!newConfig.headers?.Authorization) {
    try {
      const accessToken = await getCurrentUserToken();
      if (accessToken) {
        newConfig = {
          ...config,
          headers: {
            ...config.headers,
            Authorization: `Bearer ${accessToken}`,
          },
        };
      }
    } catch (error) {
      console.error(error);
    }
  }
  return newConfig;
};
let isRefreshing = false;

let requestArray = [];

const processQueue = (error, token = null) => {
  requestArray.forEach(
    (prom: { reject(error: IError): void; resolve(token: string): void }) => {
      if (error) {
        prom.reject(error);
      } else {
        prom.resolve(token);
      }
    },
  );

  requestArray = [];
};

const fetchRefreshToken = async (): Promise<IJitSession> => {
  try {
    const session = getSessionCookie();
    const { publicRuntimeConfig } = getConfig() as JitNextConfig;

    const user = FirebaseApiInstance.localApp.auth().currentUser;
    const userData: IFirebaseUser | undefined = user?.toJSON() as IFirebaseUser;
    const apiKey = publicRuntimeConfig.FB_API_KEY;
    const refreshToken =
      userData?.stsTokenManager.refreshToken ||
      session.user?.stsTokenManager?.refreshToken;
    const { data } = await axios.post(
      `https://securetoken.googleapis.com/v1/token?key=${apiKey}`,
      {
        grant_type: 'refresh_token',
        refresh_token: refreshToken,
      },
    );
    const stsTokenManager = camelize(data);
    const newSession = {
      ...session,
      user: { ...session.user, stsTokenManager },
    };
    removeSessionCookies();
    setSessionCookies(newSession);

    return {
      ...session,
      user: { ...session.user, stsTokenManager },
    };
  } catch (err) {
    console.log(err);
  }
};

const axiosInterceptorUnauthorizedHandler = (
  originalRequest: AxiosRequestConfig,
  axios: AxiosInstance,
) =>
  new Promise((resolve, reject) => {
    fetchRefreshToken()
      .then((session: IJitSession) => {
        processQueue(null, session.user.stsTokenManager.accessToken);
        setSessionCookies(session);
        const newOriginalRequest = {
          ...originalRequest,
          headers: {
            ...originalRequest.headers,
            Authorization: `Bearer ${session.user.stsTokenManager.accessToken}`,
          },
        };
        resolve(axios(newOriginalRequest));
      })
      .catch((err) => {
        processQueue(err, null);
        reject(err);
      })
      .finally(() => {
        isRefreshing = false;
      });
  });

export const responseInterceptor = async (
  error: IError,
  axios: AxiosInstance,
  responseParams?: IResponseParamsProps,
): Promise<any> => {
  const newError = { ...error };
  let errorMessage: string;
  if (Array.isArray(newError?.response?.data?.message)) {
    errorMessage = `formErrors.${newError?.response?.statusText}`;
  } else if (newError?.response?.data?.message) {
    errorMessage = `formErrors.${newError?.response?.data?.message}`;
  }
  const originalRequest: IConfig = error.config;
  if (originalRequest?.headers?.Authorization) {
    const isExpiredToken =
      newError?.response.data.statusCode === httpStatusCode.forbidden ||
      (newError.response.data?.message === 'Jwt is expired' &&
        newError.response.data?.statusCode === httpStatusCode.unauthorized);
    // eslint-disable-next-line no-underscore-dangle
    if (isExpiredToken && !originalRequest?._retry) {
      if (isRefreshing) {
        return new Promise((resolve, reject) => {
          requestArray.push({ resolve, reject });
        }).then((token) => {
          const newOriginalRequest = {
            ...originalRequest,
            headers: {
              ...originalRequest.headers,
              Authorization: `Bearer ${token}`,
            },
          };
          axios(newOriginalRequest);
        });
      }
      // eslint-disable-next-line no-underscore-dangle
      originalRequest._retry = true;
      isRefreshing = true;
      return axiosInterceptorUnauthorizedHandler(originalRequest, axios);
    }
  }
  if (responseParams?.t && responseParams?.addToast) {
    responseToaster({
      error: newError,
      errorMessage,
      ...responseParams,
      messageProvider: () => ({}),
    });
  }
  return Promise.reject(newError);
};
