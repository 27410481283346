import { IError } from '../interfaces/IError';
import { httpStatusCode } from '../constants/http-status-code';
import { IResponseParamsProps } from '../interfaces/api/api-utils';
import apiResponseMock from '../mock/api-response.mock';

interface IResponseToaster extends IResponseParamsProps {
  response?: any;
  error?: IError;
  status?: number;
  messageProvider?: () => { successMessage?: string };
}

export const responseToaster = ({
  addToast,
  t,
  status,
  error,
  messageProvider = () => ({}),
  errorMessage = 'formErrors.formNotSubmitted',
  warningMessage = 'formErrors.504warning',
}: IResponseToaster) => {
  const { statusCode = 0 } = error?.response?.data || apiResponseMock;
  const { successMessage } = messageProvider() || { successMessage: '' };

  if (status > 199 && status < 300) {
    return addToast(t(successMessage), {
      appearance: 'success',
      autoDismiss: true,
    });
  }
  if (
    statusCode === httpStatusCode.requestTimeOut ||
    status === httpStatusCode.requestTimeOut
  ) {
    return addToast(t(warningMessage), {
      appearance: 'warning',
      autoDismiss: true,
    });
  }
  return addToast(t(errorMessage), {
    appearance: 'error',
    autoDismiss: true,
  });
};
export default responseToaster;
