import routes from '../../routes';

const breadcrumbsCustomersLink = [
  {
    title: routes.customers.title,
    link: routes.customers.path(),
    last: true,
  },
];
export default breadcrumbsCustomersLink;
