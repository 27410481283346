import { ISelectValue } from '../../interfaces/select-value';

type ConvertDataForSelectType = (data: any[], label?: string) => ISelectValue[];

const convertDataForSelect: ConvertDataForSelectType = (data = [], label) =>
  data.map((item) => ({
    label: label
      ? item[label]
      : `${item?.firstName as string} ${item?.lastName as string}`,
    value: item?.id as string,
  }));
export default convertDataForSelect;
