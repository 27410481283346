import { FirebaseApiInstance } from './main';
import { IFirebaseUser } from '../../shared/interfaces/api/firebase/user';

export const getCurrentUserToken = async () => {
  const user =
    FirebaseApiInstance.localApp.auth().currentUser ||
    (await FirebaseApiInstance.getValidatedUser());
  const userData: IFirebaseUser | undefined = user?.toJSON() as IFirebaseUser;

  return userData?.stsTokenManager?.accessToken;
};
