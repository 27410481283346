import { AxiosInstance, AxiosResponse } from 'axios';
import {
  CustomerGroupType,
  CustomersListProps,
  ICreateTaskInterface,
  IEmployeeTimelogResponse,
  INoteProps,
  ISearchCustomerProps,
  INoteCreateInterface,
  ITaskProps,
  OrganisationCustomerProps,
  PersonCustomerProps,
  ServiceDetailsProps,
  ServiceNoteListProps,
  ServicesListProps,
  ServiceTaskListProps,
  IEmployeeTimelogDetailsResponse,
} from '@digital.jit/services/customer/ui-models';
import {
  IStepProps,
  JitMicroservice,
  IUpdateTaskStartTime,
  CustomerPersonProfileProps,
  CustomerLegalProfileProps,
  ICreateNote,
  ICreateService,
  ISaveTaskLog,
  ICreateServiceResponse,
  IDateData,
  IUploadFile,
} from '../shared/interfaces';
import { RequireAtLeastOne } from '../types';

class Customer {
  private apiPrefix = '/customer';

  private readonly headers = {
    headers: {
      jitMicroservice: JitMicroservice.customer,
    },
  };

  constructor(private readonly axios: AxiosInstance) {}

  getFirebaseToken(): Promise<AxiosResponse<{ token: string }>> {
    return this.axios.get(`${this.apiPrefix}/firebaseToken`, this.headers);
  }

  getCustomersList(): Promise<AxiosResponse<CustomersListProps[]>> {
    return this.axios.get(`${this.apiPrefix}/customersList`, this.headers);
  }

  getCustomerById(
    customerId: string,
  ): Promise<AxiosResponse<CustomersListProps>> {
    return this.axios.get(`${this.apiPrefix}/${customerId}`, this.headers);
  }

  updatePersonCustomerById(customerId: string, data: PersonCustomerProps) {
    return this.axios.put(
      `${this.apiPrefix}/person/${customerId}`,
      data,
      this.headers,
    );
  }

  updateOrganisationCustomerById(
    customerId: string,
    data: OrganisationCustomerProps,
  ) {
    return this.axios.put(
      `${this.apiPrefix}/organisation/${customerId}`,
      data,
      this.headers,
    );
  }

  async createCustomerPerson(data: CustomerPersonProfileProps) {
    return this.axios.post(`${this.apiPrefix}/person`, data, this.headers);
  }

  createCustomerLegal(data: CustomerLegalProfileProps) {
    return this.axios.post(
      `${this.apiPrefix}/organisation`,
      data,
      this.headers,
    );
  }

  searchCustomer(
    group: CustomerGroupType,
    searchString: string,
  ): Promise<AxiosResponse<ISearchCustomerProps[]>> {
    return this.axios.get(`${this.apiPrefix}/search`, {
      params: {
        group,
        searchString,
      },
      timeout: 35000,
      ...this.headers,
    });
  }

  deleteCustomer(customerId: string) {
    return this.axios.delete(`${this.apiPrefix}/${customerId}`, this.headers);
  }

  createTask(data: ICreateTaskInterface) {
    return this.axios.post(
      `${this.apiPrefix}/service/task`,
      data,
      this.headers,
    );
  }

  getTaskById(taskId: string): Promise<AxiosResponse<ITaskProps>> {
    return this.axios.get(`${this.apiPrefix}/task/${taskId}`, this.headers);
  }

  createService(data: unknown): Promise<AxiosResponse<ICreateServiceResponse>> {
    return this.axios.post(`${this.apiPrefix}/service`, data, this.headers);
  }

  duplicateService(
    serviceId: string,
    data: any,
  ): Promise<AxiosResponse<ICreateServiceResponse>> {
    return this.axios.post(
      `${this.apiPrefix}/service/${serviceId}/duplicate`,
      data,
      this.headers,
    );
  }

  createTaskNote(data: ICreateNote, taskId: string) {
    return this.axios.post(
      `${this.apiPrefix}/task/${taskId}/note`,
      data,
      this.headers,
    );
  }

  getTaskNoteList(taskId: string): Promise<AxiosResponse<INoteProps[]>> {
    return this.axios.get(
      `${this.apiPrefix}/task/${taskId}/taskNotes`,
      this.headers,
    );
  }

  getTaskSteps(taskId: string): Promise<AxiosResponse<IStepProps[]>> {
    return this.axios.get(
      `${this.apiPrefix}/task/${taskId}/steps`,
      this.headers,
    );
  }

  updateTask(taskId: string, data: ITaskProps): Promise<any> {
    return this.axios.put(
      `${this.apiPrefix}/task/${taskId}`,
      data,
      this.headers,
    );
  }

  getServicesList(): Promise<AxiosResponse<ServicesListProps[]>> {
    return this.axios.get(`${this.apiPrefix}/m/servicesList`, this.headers);
  }

  getServiceById(
    serviceId: string,
  ): Promise<AxiosResponse<ServiceDetailsProps>> {
    return this.axios.get(
      `${this.apiPrefix}/serviceWithCustomer/${serviceId}`,
      this.headers,
    );
  }

  getServiceTasks(
    serviceId: string,
  ): Promise<AxiosResponse<ServiceTaskListProps[]>> {
    return this.axios.get(
      `${this.apiPrefix}/service/${serviceId}/tasks`,
      this.headers,
    );
  }

  getServiceNoteList(
    serviceId: string,
  ): Promise<AxiosResponse<ServiceNoteListProps[]>> {
    return this.axios.get(
      `${this.apiPrefix}/service/${serviceId}/serviceNotes`,
      this.headers,
    );
  }

  createServiceNote(data: INoteCreateInterface, serviceId: string) {
    return this.axios.post(
      `${this.apiPrefix}/service/${serviceId}/note`,
      data,
      this.headers,
    );
  }

  updateTaskStep(taskId: string, stepId: string, data: IStepProps) {
    return this.axios.put(
      `${this.apiPrefix}/task/${taskId}/step/${stepId}`,
      data,
      this.headers,
    );
  }

  getTasksList(): Promise<AxiosResponse<ITaskProps[]>> {
    return this.axios.get(`${this.apiPrefix}/task/tasksList`, this.headers);
  }

  deleteServiceNote(
    serviceId: string,
    noteId: string,
  ): Promise<AxiosResponse<any>> {
    return this.axios.delete(
      `${this.apiPrefix}/service/${serviceId}/note/${noteId}`,
      this.headers,
    );
  }

  deleteTaskNote(taskId: string, noteId: string): Promise<AxiosResponse<any>> {
    return this.axios.delete(
      `${this.apiPrefix}/task/${taskId}/note/${noteId}`,
      this.headers,
    );
  }

  deleteServiceById(serviceId: string): Promise<AxiosResponse<any>> {
    return this.axios.delete(
      `${this.apiPrefix}/service/${serviceId}`,
      this.headers,
    );
  }

  deleteTaskById(taskId: string): Promise<AxiosResponse<any>> {
    return this.axios.delete(`${this.apiPrefix}/task/${taskId}`, this.headers);
  }

  updateServiceById(
    serviceId: string,
    data: ICreateService,
  ): Promise<AxiosResponse<any>> {
    return this.axios.put(
      `${this.apiPrefix}/service/${serviceId}`,
      data,
      this.headers,
    );
  }

  changeServiceCustomer(
    serviceId: string,
    customerId: string,
  ): Promise<AxiosResponse<any>> {
    return this.axios.put(
      `${this.apiPrefix}/service/${serviceId}/changeServiceCustomer`,
      { customerId },
      this.headers,
    );
  }

  changeServiceReadiness(
    serviceId: string,
    data: { isDone: boolean },
  ): Promise<AxiosResponse<any>> {
    return this.axios.put(
      `${this.apiPrefix}/service/${serviceId}/changeReadiness`,
      data,
      this.headers,
    );
  }

  getTaskForToday(): Promise<AxiosResponse<ITaskProps[]>> {
    return this.axios.get(`${this.apiPrefix}/task/tasks-today`, this.headers);
  }

  getServiceForToday(): Promise<AxiosResponse<ServicesListProps[]>> {
    return this.axios.get(
      `${this.apiPrefix}/service/service-for-today`,
      this.headers,
    );
  }

  getCustomerServices(
    customerId: string,
  ): Promise<AxiosResponse<ServicesListProps[]>> {
    return this.axios.get(
      `${this.apiPrefix}/${customerId}/services`,
      this.headers,
    );
  }

  createTaskSteps(taskId: string, steps: IStepProps[]) {
    return this.axios.post(
      `${this.apiPrefix}/task/${taskId}/create-task-step`,
      { steps },
      this.headers,
    );
  }

  updateTaskStartTime({ taskId, startDate, startTime }: IUpdateTaskStartTime) {
    return this.axios.put(
      `${this.apiPrefix}/task/${taskId}/update-start-time`,
      {
        startDate,
        startTime,
      },
      this.headers,
    );
  }

  uploadPhotos(
    data: { description: string; images: IUploadFile[] },
    taskId: string,
    stepId: string,
  ) {
    return this.axios.post(
      `${this.apiPrefix}/task/${taskId}/step/${stepId}/upload-photos`,
      data,
      this.headers,
    );
  }

  getEmployeeWorklog(
    employeeId: string,
    dates: IDateData,
  ): Promise<AxiosResponse<IEmployeeTimelogDetailsResponse>> {
    return this.axios.get(`reports/employee-timelog-list/${employeeId}`, {
      ...this.headers,
      params: dates,
    });
  }

  saveTaskWorklog(
    taskId: string,
    data: RequireAtLeastOne<ISaveTaskLog, 'actualSpendTime' | 'estimatedTime'>,
  ) {
    return this.axios.post(
      `${this.apiPrefix}/task/${taskId}/timelog`,
      data,
      this.headers,
    );
  }

  getEmployeesTimelogReport(
    startDate: Date,
    endDate: Date,
  ): Promise<AxiosResponse<IEmployeeTimelogResponse[]>> {
    return this.axios.get(`/reports/timelog-list`, {
      ...this.headers,
      params: { startDate, endDate },
    });
  }

  getEmployeeTasksList(id: string): Promise<AxiosResponse<ITaskProps[]>> {
    return this.axios.get(
      `${this.apiPrefix}/employee-tasks-list/${id}`,
      this.headers,
    );
  }
}

export default Customer;
