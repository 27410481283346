import { IAuthor } from '@digital.jit/services/customer/ui-models';
import moment from 'moment/moment';
import { dateFormat } from '../constants/dateFormat';

export const authorValue = (author: IAuthor, created: number) =>
  author && created
    ? `${author.firstName} ${author.lastName}, ${moment(created).format(
        dateFormat,
      )}`
    : null;
export default authorValue;
