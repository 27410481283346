export const createTenantSliderData = [
  {
    user: 'OfficeGuru 123',
    comment: `Fantastic service with a ton of options. If you just want the HTML to integrate with your project,
    then this is the package. You can find the files in the 'dist' folder...no need to install git and all the
    other stuff the documentation talks about.`,
  },
  {
    user: 'OfficeGuru 123',
    comment: `Fantastic service with a ton of options. If you just want the HTML to integrate with your project,
    then this is the package. You can find the files in the 'dist' folder...no need to install git and all the
    other stuff the documentation talks about.`,
  },
];
