export const fetcherKeys = {
  customers: 'customers',
  employees: 'employees',
  tasks: 'tasks',
  service: 'service',
  workflow: 'workflow',
  suggestedWorkflow: 'suggestedWorkflow',
  tasksListToday: 'tasksListToday',
  serviceListToday: 'serviceListToday',
  getSessionInformation: 'getSessionInformation',
} as const;

export type FetcherKeys = typeof fetcherKeys[keyof typeof fetcherKeys];
