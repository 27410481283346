export function getDaysString(daysCount: number, lang: string) {
  const isLastDay = daysCount === 1;
  let daysVariant = '';

  if (lang === 'en') daysVariant = 'days';
  if (lang === 'bg') daysVariant = isLastDay ? 'ден' : 'дни';
  if (lang == 'ru') {
    const firstDayStringVariant = [2, 3, 4];
    const secondDayStringVariant = [12, 13, 14];
    const remainer = daysCount % 10;
    if (
      firstDayStringVariant.indexOf(remainer) !== -1 &&
      secondDayStringVariant.indexOf(daysCount) === -1
    ) {
      daysVariant = 'дня';
    } else if (remainer === 1 || isLastDay) {
      daysVariant = 'день';
    } else {
      daysVariant = 'дней';
    }
  }
  return daysVariant;
}
