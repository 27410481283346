import { useTranslation } from 'react-i18next';

export const noteOrNotes = (notesCount: number) => {
  const { t } = useTranslation();
  const noteForm =
    notesCount === 1
      ? `${t('servicesPage.note.noteAdded')}`
      : `${t('servicesPage.note.notesAdded')}`;
  return `${notesCount} ${noteForm}`;
};
