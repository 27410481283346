interface IProps {
  [key: string]: string;
}

export function camelCase(str: string) {
  return str.replace(/[_.-](\w|$)/g, (_, x) => x.toUpperCase());
}

export const camelToSnakeCase = (str: string) =>
  str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);

type CamelType = 'camel' | 'snake';

function walk(obj: IProps | string, type: CamelType): any {
  if (!obj || typeof obj !== 'object') return obj;
  if (obj instanceof Date || obj instanceof RegExp) return obj;
  if (Array.isArray(obj)) return obj.map((c) => walk(c, type));

  return Object.keys(obj).reduce((res: { [key: string]: any }, key) => {
    const camel = type === 'camel' ? camelCase(key) : camelToSnakeCase(key);
    res[camel] = walk(obj[key], type);
    return res;
  }, {});
}

export function camelize<T = IProps>(obj: T): T extends string ? string : T {
  return typeof obj === 'string'
    ? camelCase(obj)
    : walk((obj as unknown) as IProps, 'camel');
}

export function unCamelize<T = IProps>(obj: T): T extends string ? string : T {
  return typeof obj === 'string'
    ? camelToSnakeCase(obj)
    : walk((obj as unknown) as IProps, 'snake');
}
