import { AxiosInstance, AxiosResponse } from 'axios';
import {
  ChangePasswordProps,
  EditTenantInformationProps,
  EmployeeListProps,
  InviteEmployeeProps,
  ProfileEditProps,
  ProfileEmployeePasswordProps,
  ProfileEmployeeType,
  RecoverPasswordProps,
  TenantRegistrationProps,
  UserRegistrationProps,
} from '@digital.jit/services/tenant/ui-models';
import { UserChangeActiveTenant, UserGetByEmail } from '@digital.jit/contacts';
import {
  IWorkflow,
  IWorkflowTask,
  IWorkflowTaskStep,
} from '@digital.jit/services/tenant/domain';
import {
  ICreateWorkflow,
  IStepProps,
  JitMicroservice,
} from '../shared/interfaces';
import {
  IExistingUser,
  IJitSession,
  ISessionTenant,
} from '../pages/api/auth/jitSession.interface';

class Tenant {
  private apiPrefix = '/tenant';

  private readonly headers = {
    headers: {
      jitMicroservice: JitMicroservice.tenant,
    },
  };

  constructor(private readonly axios: AxiosInstance) {}

  getUserData() {
    return this.axios.get(`${this.apiPrefix}`, this.headers);
  }

  createUser(data: UserRegistrationProps) {
    return this.axios.post(`${this.apiPrefix}/user`, data, this.headers);
  }

  createTenant(data: TenantRegistrationProps) {
    return this.axios.post<{ subdomain: string; id: string; tenantId: string }>(
      `${this.apiPrefix}`,
      data,
      this.headers,
    );
  }

  async getUserByEmail(
    email: string,
  ): Promise<AxiosResponse<UserGetByEmail.Response>> {
    return this.axios(`${this.apiPrefix}/user/get-by-email/public`, {
      ...this.headers,
      params: {
        email,
      },
    });
  }

  async getTenantByIDPid(tenantIDPId: string): Promise<AxiosResponse<any>> {
    return this.axios(`${this.apiPrefix}/get-by-idp-id/${tenantIDPId}/public`, {
      ...this.headers,
    });
  }

  recoverUserPassword(body: RecoverPasswordProps) {
    return this.axios.post(
      `${this.apiPrefix}/user/recoverPassword`,
      body,
      this.headers,
    );
  }

  createEmployee(data: InviteEmployeeProps) {
    return this.axios.post(`${this.apiPrefix}/employee`, data, this.headers);
  }

  deleteEmployee(employeeId: string) {
    return this.axios.delete(
      `${this.apiPrefix}/employee/${employeeId}`,
      this.headers,
    );
  }

  getTenantEmployeesList(): Promise<AxiosResponse<EmployeeListProps[]>> {
    return this.axios.get(`${this.apiPrefix}/employeesList`, this.headers);
  }

  getEmployeeById(
    employeeId: string,
  ): Promise<AxiosResponse<EmployeeListProps>> {
    return this.axios.get(
      `${this.apiPrefix}/employee/${employeeId}`,
      this.headers,
    );
  }

  editEmployeeProfile(employeeId: string, body: ProfileEditProps) {
    return this.axios.put(
      `${this.apiPrefix}/employee/${employeeId}`,
      body,
      this.headers,
    );
  }

  editEmployeeType(employeeId: string, body: ProfileEmployeeType) {
    return this.axios.put(
      `${this.apiPrefix}/employee/${employeeId}/changeType`,
      body,
      this.headers,
    );
  }

  editEmployeePassword(
    employeeId: string,
    body: ProfileEmployeePasswordProps,
  ): Promise<AxiosResponse<ChangePasswordProps>> {
    return this.axios.put(
      `${this.apiPrefix}/employee/${employeeId}/changePassword`,
      body,
      this.headers,
    );
  }

  editUserPassword(
    body: ProfileEmployeePasswordProps,
  ): Promise<AxiosResponse<ChangePasswordProps>> {
    return this.axios.put(
      `${this.apiPrefix}/user/changePassword`,
      body,
      this.headers,
    );
  }

  editTenantInformation(body: EditTenantInformationProps) {
    return this.axios.put(`${this.apiPrefix}`, body, this.headers);
  }

  getTenantInformation(): Promise<AxiosResponse<EditTenantInformationProps>> {
    return this.axios.get(`${this.apiPrefix}`, this.headers);
  }

  createWorkflow(data: ICreateWorkflow): Promise<AxiosResponse<any>> {
    return this.axios.post(`${this.apiPrefix}/workflow`, data, this.headers);
  }

  createWorkflowTask(
    data: any,
    workflowId: string,
  ): Promise<AxiosResponse<any>> {
    return this.axios.post(
      `${this.apiPrefix}/workflow/${workflowId}/workflowTask`,
      data,
      this.headers,
    );
  }

  getWorkflowList(): Promise<AxiosResponse<IWorkflow[]>> {
    return this.axios.get(`${this.apiPrefix}/workflowsList`, this.headers);
  }

  getSuggestedWorkflowList(): Promise<AxiosResponse<IWorkflow[]>> {
    return this.axios.get(
      `${this.apiPrefix}/suggestedWorkflowsList`,
      this.headers,
    );
  }

  getWorkflowTasksList(
    workflowId: string,
  ): Promise<AxiosResponse<IWorkflowTask[]>> {
    return this.axios.get(
      `${this.apiPrefix}/workflow/${workflowId}/workflowTasksList`,
      this.headers,
    );
  }

  getWorkflowTaskStepsList(
    taskId: string,
  ): Promise<AxiosResponse<IWorkflowTaskStep[]>> {
    return this.axios.get(
      `${this.apiPrefix}/workflow/task/${taskId}/steps`,
      this.headers,
    );
  }

  deleteWorkflowTask(workflowId: string, taskId: string) {
    return this.axios.delete(
      `${this.apiPrefix}/workflow/${workflowId}/task/${taskId}`,
      this.headers,
    );
  }

  deleteWorkflow(workflowId: string): Promise<AxiosResponse<any>> {
    return this.axios.delete(
      `${this.apiPrefix}/workflow/${workflowId}`,
      this.headers,
    );
  }

  duplicateWorkflow(
    workflowId: string,
    data: any,
  ): Promise<AxiosResponse<any>> {
    return this.axios.post(
      `${this.apiPrefix}/suggestedWorkflow/${workflowId}/duplicate`,
      data,
      this.headers,
    );
  }

  createWorkflowTaskExisting(
    data: { parentWorkflowId: string; parentTaskId: string },
    workflowId: string,
  ): Promise<AxiosResponse<any>> {
    return this.axios.post(
      `${this.apiPrefix}/workflow/${workflowId}/workflowTaskExisting`,
      data,
      this.headers,
    );
  }

  editWorkflowTaskName(
    data: { name: string },
    taskId: string,
    workflowId: string,
  ): Promise<AxiosResponse<any>> {
    return this.axios.put(
      `${this.apiPrefix}/workflow/${workflowId}/task/${taskId}`,
      data,
      this.headers,
    );
  }

  editWorkflow(
    data: ICreateWorkflow,
    workflowId: string,
  ): Promise<AxiosResponse<any>> {
    return this.axios.put(
      `${this.apiPrefix}/workflow/${workflowId}`,
      data,
      this.headers,
    );
  }

  updateWorkflowTasksPosition(
    data: {
      tasks: { id: string; positionIndex: number }[];
    },
    workflowId: string,
  ): Promise<AxiosResponse<any>> {
    return this.axios.put(
      `${this.apiPrefix}/workflow/${workflowId}/tasks-position`,
      data,
      this.headers,
    );
  }

  createWorkflowTaskSteps(
    workflowId: string,
    taskId: string,
    steps: IStepProps[],
  ): Promise<AxiosResponse<any>> {
    return this.axios.post(
      `${this.apiPrefix}/workflow/${workflowId}/task/${taskId}/add-steps`,
      { steps },
      this.headers,
    );
  }

  getWorkflowById(workflowId: string): Promise<AxiosResponse<IWorkflow>> {
    return this.axios.get(
      `${this.apiPrefix}/workflow/${workflowId}`,
      this.headers,
    );
  }

  tenantSignInSucceeded(): Promise<any> {
    return this.axios.post(
      `${this.apiPrefix}/sign-in-succeeded`,
      {},
      this.headers,
    );
  }

  getTenantsList(): Promise<AxiosResponse<any>> {
    return this.axios.get(`${this.apiPrefix}/user/tenants-list`, this.headers);
  }

  changeActiveTenant(
    tenantIDPid: string,
  ): Promise<
    AxiosResponse<
      UserChangeActiveTenant.Response<ISessionTenant, IExistingUser>
    >
  > {
    return this.axios.post(
      `${this.apiPrefix}/user/change-active-tenant`,
      { tenantIDPid },
      this.headers,
    );
  }

  getUserSessionInformation(): Promise<AxiosResponse<IJitSession>> {
    return this.axios.get(
      `${this.apiPrefix}/user/get-session-information`,
      this.headers,
    );
  }
}

export default Tenant;
