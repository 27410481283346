import routes from '../../routes';

const breadcrumbsTaskCreate = [
  {
    title: routes.services.title,
    link: routes.services.path(),
  },
  {
    title: routes.tasks.title,
    link: routes.tasks.path(),
    last: true,
  },
];
export default breadcrumbsTaskCreate;
