export const valueToColor = (val: number, isDone = false): string => {
  if (val === 100 || isDone) {
    return '#34C38F';
  }
  if (val >= 75 && val < 100) {
    return '#F1B44C';
  }
  if (val >= 0 && val < 75) {
    return '#F46A6A';
  }
  if (val === 0) {
    return '#B0B0B0';
  }

  return '';
};

export default valueToColor;
