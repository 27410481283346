import axios, { AxiosInstance } from 'axios';
import getConfig from 'next/config';
import Tenant from './tenant';
import Customer from './customer';
import { JitNextConfig } from '../types/jit-next-config.interface';
import { requestInterceptor, responseInterceptor } from './utils/utils';
import { IConfig } from '../shared/interfaces/rest-api-config';
import responseToaster from '../shared/helpers/response-toaster';
import { IResponseParamsProps } from '../shared/interfaces/api/api-utils';

export class RestApiClient {
  private readonly axios: AxiosInstance;

  public tenant: Tenant;

  public customer: Customer;

  private readonly apiVersion = 'api/v1';

  constructor(
    responseParams?: IResponseParamsProps,
    messageProvider = () => ({}),
    shouldShowToaster = false,
  ) {
    const { publicRuntimeConfig } = getConfig() as JitNextConfig;

    this.axios = axios.create({
      baseURL: `${publicRuntimeConfig.NEXT_PUBLIC_BACKEND_URL}/${this.apiVersion}`,
    });

    this.axios.interceptors.request.use(
      async (config: IConfig) => requestInterceptor(config, this.apiVersion),
      (error) => Promise.reject(error),
    );
    this.axios.interceptors.response.use(
      (response) => {
        const {
          status,
          config: { method },
        } = response;
        if (method.toLowerCase() !== 'get' && shouldShowToaster) {
          responseToaster({
            status,
            ...responseParams,
            messageProvider,
          });
        }
        return response;
      },
      (error) =>
        responseInterceptor(
          error,
          this.axios,
          responseParams && responseParams,
        ),
    );
    this.tenant = new Tenant(this.axios);
    this.customer = new Customer(this.axios);
  }
}

export default new RestApiClient();
