import routes from '../../routes';

const breadcrumbsServiceCreate = [
  { title: routes.customers.title, link: routes.customers.path() },
  {
    title: routes.services.title,
    link: routes.services.path(),
    last: true,
  },
];
export default breadcrumbsServiceCreate;
