/* eslint-disable prettier/prettier */
// conflict prettier and eslint

export const removeEmpty = (obj: unknown) =>
  Object.keys(obj)
    .filter((key) => obj[key])
    .reduce((newObj, key) => {
      const temporaryObj = { ...newObj };
      temporaryObj[key] = obj[key] as string | number;
      return temporaryObj;
    }, {});

export default removeEmpty;
